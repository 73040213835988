
const dev = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "notes-app-dev",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_9CpeTVgIr",
        APP_CLIENT_ID: "6tckbdr7s2ulu3u4ara1uvc4jg",
        IDENTITY_POOL_ID: "us-east-1:4b73975b-d850-40bb-b3d2-d72071127a6a"
    }
};

const prod = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "notes-app-prod",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "",
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_9CpeTVgIr",
        APP_CLIENT_ID: "6tckbdr7s2ulu3u4ara1uvc4jg",
        IDENTITY_POOL_ID: "us-east-1:4b73975b-d850-40bb-b3d2-d72071127a6a"
    }
};

const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};